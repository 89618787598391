export type MarketName =
  | 'KRW-BTC'
  | 'KRW-ETH'
  | 'KRW-DOGE'
  | 'KRW-SOL'
  | 'KRW-TRX'
  | 'KRW-XRP'
  | 'KRW-ADA';

export const AVAILABLE_MARKETS: Array<{ name: MarketName; kr_name: string }> = [
  {
    name: 'KRW-BTC',
    kr_name: '비트코인',
  },
  {
    name: 'KRW-ETH',
    kr_name: '이더리움',
  },
  {
    name: 'KRW-DOGE',
    kr_name: '도지코인',
  },
  {
    name: 'KRW-SOL',
    kr_name: '솔라나',
  },
  {
    name: 'KRW-TRX',
    kr_name: '트론',
  },
  {
    name: 'KRW-XRP',
    kr_name: '리플',
  },
  {
    name: 'KRW-ADA',
    kr_name: '에이다',
  },
];
