import { useEffect } from 'react';

import { MarketName } from 'constants/';

import { useStrategyPageModal } from 'components/Modals/StrategyPageModals';

/*
 * Return the selected market value with syncing that of redux-state.
 */
export const useSelectMarket = (
  callback: (market: MarketName) => void,
): MarketName => {
  const { context } = useStrategyPageModal();

  useEffect(() => {
    if (context.selectedMarket) {
      callback(context.selectedMarket);
    }
  }, [callback, context.selectedMarket]);

  return context.selectedMarket;
};
