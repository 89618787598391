import { useState } from 'react';

import { AVAILABLE_MARKETS, MarketName } from 'constants/';
import { useAppSelector } from 'store';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import { Market } from 'features/schemas/client';

import Button from 'components/Button';

import { SelectValueModalProps } from 'components/Modal/ModalTypes';

import SelectModal from 'components/Modal/SelectModal/SelectModal';

import {
  SelectMarketModalHeaderRow,
  SelectMarketModalMarketRow,
} from 'components/Row/SelectMarketModalRow';

import styles from 'components/Modal/SelectMarketModal/SelectMarketModal.module.scss';

type SelectMarketModalProps = Omit<
  SelectValueModalProps<MarketName>,
  'onSubmit'
>;

const SelectMarketModal = ({ onSelect, onClose }: SelectMarketModalProps) => {
  const initialSelectedMarket = useAppSelector(
    (state) => state.backtestingSetting.market,
  );

  const [selectedMarketName, setSelectedMarketName] = useState<MarketName>(
    initialSelectedMarket,
  );
  const isValid = Boolean(selectedMarketName);

  const handleCloseClick = () => {
    trackEvent(amplitudeEventNames.BTN_SELECT_PAIR_MODAL_CLOSE, {
      select_pair_modal_selected: selectedMarketName,
      select_pair_modal_close_position: 'header',
    });
    onClose();
  };

  const handleSubmitButtonClick = () => {
    trackEvent(amplitudeEventNames.BTN_SELECT_PAIR_MODAL_CLOSE, {
      select_pair_modal_selected: selectedMarketName,
      select_pair_modal_close_position: 'bottom',
    });

    onSelect(selectedMarketName);
  };

  const handleMarketSelect = (market: Market) => {
    setSelectedMarketName(market.name);
  };

  return (
    <SelectModal
      title={'백테스팅할 종목 1개를 선택해 주세요.'}
      onClose={handleCloseClick}
      width={750}
      height={'auto'}
    >
      <div className={styles.root}>
        <div className={styles.rowContainer}>
          <SelectMarketModalHeaderRow />
          <div className={styles.rowListWrapper}>
            {AVAILABLE_MARKETS.map((market) => {
              return (
                <SelectMarketModalMarketRow
                  key={market.name}
                  market={market}
                  isSelected={selectedMarketName === market.name}
                  handleSelect={() => handleMarketSelect(market)}
                />
              );
            })}
          </div>
        </div>
        <div className={styles.confirmButtonContainer}>
          <Button
            onClick={handleSubmitButtonClick}
            isClickable={isValid}
            theme="primary1"
            size="medium"
          >
            선택 완료
          </Button>
        </div>
      </div>
    </SelectModal>
  );
};

export default SelectMarketModal;
