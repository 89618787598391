import { useRouter } from 'next/router';

import { PropsWithChildren, useEffect } from 'react';

import { routes } from 'constants/';
import { useResponsive } from 'hooks';
import { useSession } from 'next-auth/react';

import styles from './index.module.scss';

const UnSupportedLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const { isUnSupported } = useResponsive();
  const router = useRouter();
  const { data: session, status } = useSession();

  useEffect(() => {
    if (!isUnSupported) {
      return;
    }

    if (status === 'authenticated') {
      router.push(routes.myStrategyRoute(session.username));
      return;
    }

    router.push('/');
  }, [isUnSupported, router, session, status]);

  if (isUnSupported) {
    return (
      <div className={styles.unsupport}>
        <div>현재 이 페이지는</div>
        <div>PC 에서만 이용 가능해요 :(</div>
      </div>
    );
  }

  return <>{children}</>;
};

export default UnSupportedLayout;
