import { PropsWithChildren } from 'react';

import clsx from 'clsx';

import styles from 'components/Modal/ConfirmStartBacktestingModal/ConfirmStartBacktestingModalRow.module.scss';

interface ConfirmStartBacktestingModalRowProps {
  title: string;
  extraClassName?: string;
}

export const ConfirmStartBacktestingModalRow = ({
  title,
  extraClassName,
  children,
}: PropsWithChildren<ConfirmStartBacktestingModalRowProps>) => {
  return (
    <div className={clsx(styles.root, extraClassName)}>
      <div className={styles.title}>{title}</div>
      {children}
    </div>
  );
};
