import clsx from 'clsx';

import { Market } from 'features/schemas/client';

import Radio from 'components/Input/Radio';

import styles from 'components/Row/SelectMarketModalRow.module.scss';

export const SelectMarketModalHeaderRow = () => {
  return (
    <div className={clsx(styles.root, styles.headerRoot)}>
      <div className={styles.selectColumn}></div>
      <div className={styles.marketColumn}>
        <span className={styles.rowText}>종목명</span>
      </div>
    </div>
  );
};

interface SelectMarketModalMarketRowProps {
  market: Market;
  isSelected: boolean;
  handleSelect: () => void;
}

export const SelectMarketModalMarketRow = ({
  market,
  isSelected,
  handleSelect,
}: SelectMarketModalMarketRowProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.selectColumn}>
        {
          <Radio
            isSelected={isSelected}
            onChange={handleSelect}
            title={market.name.split('-')[1]}
            isLabelVisible={false}
          />
        }
      </div>
      <div className={styles.marketColumn}>
        <span className={styles.rowText}>
          {market.name.split('-')[1]}
          <span className={styles.grayText}>{market.kr_name!}</span>
        </span>
      </div>
    </div>
  );
};
