import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  CandleKind,
  CryptoExchange,
  logicalOperators,
  MarketName,
} from 'constants/';

import { differenceInDays, format, sub, add } from 'date-fns';

import {
  convertExprsToStrategy,
  validateExprsToFormulas,
} from 'features/adaptor/validators';
import { MyStrategyDetail } from 'features/api/chart/strategy/type';
import { env } from 'features/env';
import { StrategyArray, Formula, TokenValue } from 'features/schemas/client';

import {
  checkNotStartingWithZero,
  countTimeGap,
  fireAlert,
  formatCurrency,
  getNextAlphabet,
  parseCurrencyInput,
  splitDateTimeOfISOString,
} from 'features/utils';

import { validateFormulas } from 'features/utils/validateStrategy';

const now = new Date();
const today = format(now, 'yyyy-MM-dd');
const oneMonthAgo = format(sub(now, { months: 1 }), 'yyyy-MM-dd');
const DEFAULT_PRINCIPAL = 1_000;
const MAX_PRINCIPAL = 50_000;
const BACKTESTING_PERIOD_LIMIT = 365 * 5;

interface ConditionExpression {
  selected: string;
  buyStrategy: StrategyArray;
  sellStrategy: StrategyArray;
}

interface SellConfig {
  profit_cut: number | string | null;
  loss_cut: number | string | null;
  market_max_holding_minutes: number | string;
}

const initialConditionalStatement: ConditionExpression = {
  selected: '',
  buyStrategy: [],
  sellStrategy: [],
};

export const initialSellConfig: SellConfig = {
  profit_cut: 5.0,
  loss_cut: 5.0,
  market_max_holding_minutes: '14400',
};

export type StrategyMode = 'buy' | 'sell';

export interface BacktestingSettingState {
  id: string | null;
  exchange: CryptoExchange;
  principal: string;
  startAndEndDate: string[];
  startAndEndTime: string[];
  startAndEndGapsDHM: [number, number, number]; // [일, 시간, 분]
  market: MarketName;
  createdAt?: string;
  name: string;
  strategyMode: StrategyMode;
  conditionExpression: ConditionExpression;
  sellConfig: SellConfig;
  buyFormulas: Formula[];
  sellFormulas: Formula[];
  selectedBuyFormulaIndex: number;
  selectedSellFormulaIndex: number;
}

interface FormulaIndexPayload {
  strategyMode: StrategyMode;
  index: number;
}

interface FormulaPayload {
  strategyMode: StrategyMode;
  tokens: TokenValue[];
  kind: CandleKind;
}

export const getInitialBacktestingSettingState: () => BacktestingSettingState =
  () => ({
    id: null,
    exchange: 'coinone',
    principal: DEFAULT_PRINCIPAL.toLocaleString(),
    startAndEndDate: [oneMonthAgo, today],
    startAndEndTime: ['00:00', '23:59'],
    startAndEndGapsDHM: [
      differenceInDays(now, sub(now, { months: 1 })),
      23,
      59,
    ],
    market: 'KRW-BTC',
    createdAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    name: format(new Date(), 'yyyyMMdd_HHmmss'),
    strategyMode: 'buy',
    conditionExpression: initialConditionalStatement,
    sellConfig: initialSellConfig,
    editInfo: null,
    buyFormulas: [],
    sellFormulas: [],
    selectedBuyFormulaIndex: -1,
    selectedSellFormulaIndex: -1,
  });

const _editPrincipal: CaseReducer<
  BacktestingSettingState,
  PayloadAction<string>
> = (state, action) => {
  const sanitizedValue = action.payload.replaceAll(',', '');

  if (!checkNotStartingWithZero(sanitizedValue) || Number(sanitizedValue) < 1) {
    state.principal = '1';
    return;
  }

  if (Number(sanitizedValue) > MAX_PRINCIPAL) {
    state.principal = MAX_PRINCIPAL.toLocaleString();
    return;
  }

  state.principal = parseCurrencyInput(state.principal, sanitizedValue)!;
};

const _changeDate: CaseReducer<
  BacktestingSettingState,
  PayloadAction<[number, string]>
> = (state, action) => {
  const endDay = state.startAndEndDate[1];
  const [dateIndex, newDate] = action.payload;
  if (dateIndex === 0) {
    if (newDate > endDay) {
      state.startAndEndDate[dateIndex] = endDay;
    } else {
      if (
        newDate === endDay &&
        state.startAndEndTime[0] > state.startAndEndTime[1]
      ) {
        state.startAndEndTime[0] = state.startAndEndTime[1];
        state.startAndEndGapsDHM[1] = 0;
        state.startAndEndGapsDHM[2] = 0;
      }
      state.startAndEndDate[dateIndex] = newDate;
    }
  } else {
    if (newDate < state.startAndEndDate[0]) {
      state.startAndEndDate[0] = newDate;
      state.startAndEndDate[1] = newDate;
    } else {
      state.startAndEndDate[dateIndex] = newDate;
    }
  }

  const difference = Math.abs(
    differenceInDays(
      new Date(state.startAndEndDate[0]),
      new Date(state.startAndEndDate[1]),
    ),
  );

  if (env.STAGE !== 'dev' && difference > BACKTESTING_PERIOD_LIMIT) {
    // MEMO: TB-4718, limit period under 5 years in production
    const oneYearFromEnd = format(
      sub(new Date(state.startAndEndDate[1]), { years: 5 }),
      'yyyy-MM-dd',
    );

    const oneYearFromStart = format(
      add(new Date(state.startAndEndDate[0]), { years: 5 }),
      'yyyy-MM-dd',
    );
    dateIndex === 0
      ? (state.startAndEndDate[dateIndex] = oneYearFromEnd)
      : (state.startAndEndDate[dateIndex] = oneYearFromStart);
    state.startAndEndGapsDHM[0] = BACKTESTING_PERIOD_LIMIT;
  } else {
    state.startAndEndGapsDHM[0] = difference;
  }
};

const _selectMarket: CaseReducer<
  BacktestingSettingState,
  PayloadAction<MarketName>
> = (state, action) => {
  state.market = action.payload;
};

const _setStrategyName: CaseReducer<
  BacktestingSettingState,
  PayloadAction<string>
> = (state, action) => {
  state.name = action.payload;
};

const _setStrategyMode: CaseReducer<
  BacktestingSettingState,
  PayloadAction<StrategyMode>
> = (state, action) => {
  state.strategyMode = action.payload;
};

const _addNewDefaultBuyFormula: CaseReducer<BacktestingSettingState> = (
  state,
) => {
  const lastFormula = state.buyFormulas.at(-1);

  const newFormula: Formula = {
    name: getNextAlphabet(lastFormula?.name),
    tokens: [],
    kind: 'day',
  };

  // 포뮬라가 하나도 없을 때
  if (!lastFormula) {
    state.buyFormulas = [newFormula];
    state.selectedBuyFormulaIndex = 0;
    state.conditionExpression = {
      ...state.conditionExpression,
      buyStrategy: [newFormula.name],
    };
    return;
  }

  // MEMO: 7개로 제한한 이유, TB-4304
  if (lastFormula && state.buyFormulas.length >= 7) {
    fireAlert({
      text: '포뮬라 생성은 7개까지 가능해요. 기존 포뮬라를 삭제하고 생성해주세요.',
    });
    return;
  }

  state.buyFormulas = [...state.buyFormulas, newFormula];
  state.selectedBuyFormulaIndex =
    state.buyFormulas.length > 0 ? state.buyFormulas.length - 1 : 0;
  state.conditionExpression = {
    ...state.conditionExpression,
    buyStrategy: [
      ...state.conditionExpression.buyStrategy,
      'and',
      newFormula.name,
    ],
  };
};

const _addNewDefaultSellFormula: CaseReducer<BacktestingSettingState> = (
  state,
) => {
  const lastFormula = state.sellFormulas.at(-1);

  const newFormula: Formula = {
    name: getNextAlphabet(lastFormula?.name),
    tokens: [],
    kind: 'day',
  };

  // 포뮬라가 하나도 없을 때
  if (!lastFormula) {
    state.sellFormulas = [newFormula];
    state.selectedSellFormulaIndex = 0;
    state.conditionExpression = {
      ...state.conditionExpression,
      sellStrategy: [newFormula.name],
    };
    return;
  }

  // MEMO: 7개로 제한한 이유, TB-4304
  if (lastFormula && state.sellFormulas.length >= 7) {
    fireAlert({
      text: '포뮬라 생성은 7개까지 가능해요. 기존 포뮬라를 삭제하고 생성해주세요.',
    });
    return;
  }

  state.sellFormulas = [...state.sellFormulas, newFormula];
  state.selectedSellFormulaIndex =
    state.sellFormulas.length > 0 ? state.sellFormulas.length - 1 : 0;
  state.conditionExpression = {
    ...state.conditionExpression,
    sellStrategy: [
      ...state.conditionExpression.sellStrategy,
      'and',
      newFormula.name,
    ],
  };
};

const _setFormula: CaseReducer<
  BacktestingSettingState,
  PayloadAction<FormulaPayload>
> = (state, action) => {
  const { strategyMode, tokens, kind } = action.payload;
  const formulas = strategyMode === 'buy' ? 'buyFormulas' : 'sellFormulas';
  const selectedFormulaIndex =
    strategyMode === 'buy'
      ? state.selectedBuyFormulaIndex
      : state.selectedSellFormulaIndex;

  if (selectedFormulaIndex === -1) {
    return;
  }

  const originalFormula = state[formulas][selectedFormulaIndex];
  state[formulas][selectedFormulaIndex] = {
    ...originalFormula,
    tokens,
    kind,
  };
};

const _setSelectedFormulaIndex: CaseReducer<
  BacktestingSettingState,
  PayloadAction<FormulaIndexPayload>
> = (state, action) => {
  const { strategyMode, index } = action.payload;
  const selectedFormulaIndex =
    strategyMode === 'buy'
      ? 'selectedBuyFormulaIndex'
      : 'selectedSellFormulaIndex';
  state[selectedFormulaIndex] = index;
};

const _setSellConfig: CaseReducer<
  BacktestingSettingState,
  PayloadAction<SellConfig>
> = (state, action) => {
  state.sellConfig = action.payload;
};

const _deleteFormulaToken: CaseReducer<
  BacktestingSettingState,
  PayloadAction<{ index: number; strategyMode: StrategyMode }>
> = (state, action) => {
  const { index, strategyMode } = action.payload;
  const formulas = strategyMode === 'buy' ? 'buyFormulas' : 'sellFormulas';
  const strategy = strategyMode === 'buy' ? 'buyStrategy' : 'sellStrategy';
  const [deletedFormula] = state[formulas].splice(index, 1);

  if (strategyMode === 'buy') {
    state.selectedBuyFormulaIndex = -1;
  }

  if (strategyMode === 'sell') {
    state.selectedSellFormulaIndex = -1;
  }

  state.conditionExpression = {
    ...state.conditionExpression,
    [strategy]: state.conditionExpression[strategy].filter(
      (x) => x !== deletedFormula.name,
    ),
  };

  // // TB-4626(요구사항3), 포뮬라를 지우면 식의 무결성을 유지하기 위해 관련 연산자도 지워줘야 함
  let newStrategy = structuredClone(
    state.conditionExpression[strategy].filter(
      (x) => x !== deletedFormula.name,
    ),
  );

  // 1. and B and C, Remove leading operators
  while (newStrategy.length > 0 && logicalOperators.includes(newStrategy[0])) {
    newStrategy.shift();
  }

  // 2. A and B and, Remove trailing operators
  while (
    newStrategy.length > 0 &&
    logicalOperators.includes(newStrategy[newStrategy.length - 1])
  ) {
    newStrategy.pop();
  }

  // 3. A and and C, Remove consecutive operators
  newStrategy = newStrategy.filter(
    (item, i) =>
      !(
        logicalOperators.includes(item) &&
        logicalOperators.includes(newStrategy[i + 1])
      ),
  );

  state.conditionExpression = {
    ...state.conditionExpression,
    [strategy]: newStrategy,
  };
};

const _setConditionalStatement: CaseReducer<
  BacktestingSettingState,
  PayloadAction<ConditionExpression>
> = (state, action) => {
  const data = action.payload;

  state.conditionExpression = Object.assign({}, state.conditionExpression, {
    ...data,
  });
};

const _importSettings: CaseReducer<
  BacktestingSettingState,
  PayloadAction<MyStrategyDetail>
> = (state, action) => {
  const settings = action.payload;

  state.id = settings.id;
  state.name = settings.name;
  state.exchange = 'coinone';
  state.principal = settings.principal
    ? formatCurrency(settings.principal / 10_000) ?? '0'
    : '0';

  const { date: startDate, time: startTime } = splitDateTimeOfISOString(
    settings.start,
  );
  const { date: endDate, time: endTime } = splitDateTimeOfISOString(
    settings.end,
  );
  state.startAndEndDate = [startDate, endDate];
  state.startAndEndTime = [startTime, endTime];

  const difference = differenceInDays(new Date(startDate), new Date(endDate));
  const [startHour, startMinute] = startTime.split(':').map(Number);
  const [endHour, endMinute] = endTime.split(':').map(Number);
  const hourGap = startHour - endHour;
  const minuteGap = startMinute - endMinute;
  const [calculatedHour, calculatedMinute] = countTimeGap(hourGap, minuteGap);

  state.startAndEndGapsDHM = [
    Math.abs(difference),
    calculatedHour,
    calculatedMinute,
  ];

  [state.market] = settings.markets;
  state.createdAt = format(
    new Date(settings.created_at),
    'yyyy-MM-dd HH:mm:ss',
  );

  const buyStrategyArray = convertExprsToStrategy(
    settings.strategy.buy_exprs || [],
  );
  const sellStrategyArray = convertExprsToStrategy(
    settings.strategy.sell_exprs || [],
  );

  state.conditionExpression = {
    selected: '',
    buyStrategy: buyStrategyArray,
    sellStrategy: sellStrategyArray,
  };

  state.sellConfig = {
    profit_cut: Number(settings.strategy.profit_cut),
    loss_cut: Number(settings.strategy.loss_cut),
    market_max_holding_minutes:
      settings.strategy.market_max_holding_minutes || 0,
  };

  state.buyFormulas = validateExprsToFormulas(settings.strategy.buy_exprs);
  state.sellFormulas = validateExprsToFormulas(
    settings.strategy.sell_exprs || [],
  );
  state.selectedBuyFormulaIndex = 0;
  state.selectedSellFormulaIndex = 0;

  return state;
};

export const backtestingSettingSlice = createSlice({
  name: 'backtestingSetting',
  initialState: getInitialBacktestingSettingState(),
  reducers: {
    setStrategyName: _setStrategyName,
    editPrincipal: _editPrincipal,
    changeDate: _changeDate,
    selectMarket: _selectMarket,
    importSettings: _importSettings,
    setSellConfig: _setSellConfig,
    deleteFormulaToken: _deleteFormulaToken,
    setConditionalStatement: _setConditionalStatement,
    setStrategyMode: _setStrategyMode,
    addNewDefaultBuyFormula: _addNewDefaultBuyFormula,
    addNewDefaultSellFormula: _addNewDefaultSellFormula,
    setFormula: _setFormula,
    setSelectedFormulaIndex: _setSelectedFormulaIndex,
  },
});

export const {
  editPrincipal,
  changeDate,
  selectMarket,
  setStrategyName,
  setSellConfig,
  deleteFormulaToken,
  setConditionalStatement,
  setStrategyMode,
  addNewDefaultBuyFormula,
  addNewDefaultSellFormula,
  setFormula,
  setSelectedFormulaIndex,
  importSettings,
} = backtestingSettingSlice.actions;

const validateSellStrategy = (strategy: SellConfig) => {
  if (!strategy.market_max_holding_minutes) {
    return false;
  }

  if (
    Object.prototype.hasOwnProperty.call(strategy, 'profit_cut') &&
    strategy.profit_cut !== null &&
    (Number(strategy.profit_cut) <= 0 || isNaN(Number(strategy.profit_cut)))
  ) {
    return false;
  }

  if (
    Object.prototype.hasOwnProperty.call(strategy, 'loss_cut') &&
    strategy.loss_cut !== null &&
    (Number(strategy.loss_cut) <= 0 || isNaN(Number(strategy.loss_cut)))
  ) {
    return false;
  }

  return true;
};

export const getValidationState = (
  backtestingSetting: BacktestingSettingState,
) => {
  const {
    principal,
    startAndEndDate,
    market,
    conditionExpression,
    buyFormulas,
    sellFormulas,
    sellConfig,
  } = backtestingSetting;

  const isBasicSettingValid =
    Boolean(principal) && startAndEndDate.length === 2;

  const isMarketSettingValid = Boolean(market);

  const isBuyStrategySettingValid =
    conditionExpression.buyStrategy.length > 0 &&
    validateFormulas(buyFormulas) &&
    buyFormulas.length > 0 &&
    buyFormulas[0].tokens.length > 0;

  const isSellStrategySettingValid =
    validateSellStrategy(sellConfig) &&
    (sellFormulas.length === 0 || validateFormulas(sellFormulas));

  return {
    isBasicSettingValid,
    isMarketSettingValid,
    isBuyStrategySettingValid,
    isSellStrategySettingValid,
  };
};
