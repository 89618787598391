import { PropsWithChildren } from 'react';

import { clsx } from 'clsx';

import { menuItems } from 'constants/';

import { Header } from 'components/Layouts';

import styles from 'components/Layouts/GNBLayout/GNBLayout.module.scss';

const GNBLayout: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <main className={clsx(styles.wrap)}>
      <Header items={menuItems} />
      <div className={clsx(styles.contentWrap)}>{children}</div>
    </main>
  );
};

export default GNBLayout;
