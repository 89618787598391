import { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from 'store';

import strategyQuery from 'hooks/useQuery/strategy';

import { MyStrategyDetail } from 'features/api/chart/strategy/type';
import { importSettings } from 'features/slices';

type FetchStrategyInfo = {
  strategyId: string;
  callback: (strategy: MyStrategyDetail) => void;
};

export const useLoadStrategy = () => {
  const [fetchStrategyInfo, setFetchStrategyInfo] =
    useState<FetchStrategyInfo | null>(null);
  const { data: strategyDetail } = strategyQuery.useStrategy({
    strategyId: String(fetchStrategyInfo?.strategyId),
    options: {
      enabled: Boolean(fetchStrategyInfo?.strategyId),
    },
  });
  const dispatch = useAppDispatch();

  const loadStrategy = useCallback(
    (backtestingAndStrategy: MyStrategyDetail) => {
      dispatch(importSettings(backtestingAndStrategy));
    },
    [dispatch],
  );

  useEffect(() => {
    if (strategyDetail && fetchStrategyInfo) {
      fetchStrategyInfo?.callback(strategyDetail);
    }
  }, [fetchStrategyInfo, strategyDetail, loadStrategy]);

  const fetchStrategy = (
    strategyId: string,
    cb: (strategy: MyStrategyDetail) => void,
  ) => {
    setFetchStrategyInfo({ strategyId, callback: cb });
  };

  return { loadStrategy, fetchStrategy };
};
