import { useMemo, useState } from 'react';

import clsx from 'clsx';

import { IndicatorInfo } from 'constants/';

import SearchableDropdown from 'components/Dropdown/SearchableDropdown';
import TooltipIcon from 'components/Icon/TooltipIcon';
import RatelTooltip from 'components/RatelTooltip';
import { ScrollContextProvider } from 'components/Scroll/ScrollContext';

import styles from 'components/Dropdown/IndicatorDropdown.module.scss';

interface IndicatorDropdownProps {
  value: IndicatorInfo | null;
  setValue: (v: IndicatorInfo) => void;
  height?: number;
  indicators: IndicatorInfo[];
  excludedIndicatorKrNames?: string[];
}

interface IndicatorDropdownRowProps {
  indicator: IndicatorInfo;
  isSelected: boolean;
  isInactive: boolean;
}

const IndicatorDropdownRow = ({
  indicator,
  isSelected,
  isInactive,
}: IndicatorDropdownRowProps) => {
  return (
    <div
      className={clsx(
        styles.option,
        isSelected && styles.selectedOption,
        isInactive && styles.inactiveOption,
      )}
    >
      <div>
        <p>{indicator.kr_name}</p>
        <p>({indicator.en_name})</p>
      </div>
      <TooltipIcon id={indicator.en_name} />
      <RatelTooltip
        id={indicator.en_name}
        title={`[${indicator.kr_name}]`}
        description={indicator.description}
      />
    </div>
  );
};

const IndicatorDropdown = ({
  value,
  setValue,
  height,
  indicators,
  excludedIndicatorKrNames,
}: IndicatorDropdownProps) => {
  const [currentKey, setCurrentKey] = useState<string | null>(
    value ? value.kr_name : null,
  );

  const getKey = (indicator: IndicatorInfo) =>
    `${indicator.kr_name} (${indicator.en_name})`;

  const keyToIndicatorMap = useMemo(() => {
    const map: Record<string, IndicatorInfo> = {};
    indicators.forEach((indicator) => {
      map[getKey(indicator)] = indicator;
    });

    return map;
  }, [indicators]);

  const inactiveKeys = useMemo(() => {
    return indicators
      .filter((v) => excludedIndicatorKrNames?.includes(v.kr_name))
      .map((v) => getKey(v));
  }, [indicators, excludedIndicatorKrNames]);

  const renderRow = (key: string, currentKey: string | null) => {
    const indicator = keyToIndicatorMap[key];
    return (
      <IndicatorDropdownRow
        indicator={indicator}
        isSelected={key === currentKey}
        isInactive={inactiveKeys.includes(key)}
      />
    );
  };

  const categoryToKeysMap = {
    전체: indicators.map((v) => getKey(v)),
    기본: indicators
      .filter(({ category }) => category === 'general')
      .map((v) => getKey(v)),
    추세: indicators
      .filter(({ category }) => category === 'trends')
      .map((v) => getKey(v)),
    모멘텀: indicators
      .filter(({ category }) => category === 'momentum')
      .map((v) => getKey(v)),
    변동성: indicators
      .filter(({ category }) => category === 'volatility')
      .map((v) => getKey(v)),
    온체인데이터: indicators
      .filter(({ category }) => category === 'onchain_data')
      .map((v) => getKey(v)),
    // 백엔드에서 현재 해당되는 카테고리의 값을 내려주고 있지 않기 때문에
    // 잠시 주석처리 (해당 카테고리를 누르면 빈 박스가 나와서 못생겼음)
    // 매수: indicators
    //   .filter(({ category }) => category === 'buy_price')
    //   .map((v) => getKey(v)),
  };

  const setKey = (key: string) => {
    setCurrentKey(key);
    setValue(keyToIndicatorMap[key]);
  };

  return (
    <ScrollContextProvider scrollType="scrollTop">
      <SearchableDropdown
        currentKey={currentKey}
        setKey={setKey}
        categoryToKeysMap={categoryToKeysMap}
        height={height}
        isEditable
        separateCategory
        renderRow={renderRow}
        inactiveKeys={inactiveKeys}
      />
    </ScrollContextProvider>
  );
};

export default IndicatorDropdown;
