import * as Sentry from '@sentry/nextjs';

import { useAppSelector } from 'store';

import backtestingMutation from 'hooks/useMutation/backtesting';

import { convertStateToBacktestingForm } from 'features/adaptor/converters/';
import { amplitudeEventNames, trackEvent } from 'features/amplitude';
import { formatCurrency, parseNumberFormatString } from 'features/utils';

import Button from 'components/Button';

import { ConfirmStartBacktestingModalRow } from 'components/Modal/ConfirmStartBacktestingModal/ConfirmStartBacktestingModalRow';
import { SimpleModalProps } from 'components/Modal/ModalTypes';
import SelectModal from 'components/Modal/SelectModal/SelectModal';

import TextHighlight from 'components/Textbox/TextHighlight';

import styles from 'components/Modal/ConfirmStartBacktestingModal/ConfirmStartBacktestingModal.module.scss';

const ConfirmStartBacktestingModal = ({ onClose }: SimpleModalProps) => {
  const backtestingSettingState = useAppSelector((state) => {
    return state.backtestingSetting;
  });

  const {
    id,
    name,
    principal,
    startAndEndDate,
    startAndEndTime,
    startAndEndGapsDHM,
  } = backtestingSettingState;

  const [startDate, endDate] = startAndEndDate;
  const [startTime, endTime] = startAndEndTime;
  const [dayGap, hourGap, minuteGap] = startAndEndGapsDHM;

  const form = convertStateToBacktestingForm(backtestingSettingState);
  const { mutate } = backtestingMutation.useCreateBacktestingTaskMutation();

  const handleCancelClick = () => {
    trackEvent(amplitudeEventNames.BTN_BT_CANCEL);
    onClose();
  };

  const handleStartClick = async () => {
    trackEvent(amplitudeEventNames.BTN_BT_START, form);

    Sentry.captureMessage('backtesting form submit!!', {
      level: 'info',
      extra: { form },
    });
    mutate(form);
  };

  const handleStartWithEditClick = async () => {
    trackEvent(amplitudeEventNames.BTN_BT_EDIT, form);

    if (id) {
      form.backtesting_id = id;
    }

    Sentry.captureMessage('backtesting form submit!!', {
      level: 'info',
      extra: { form },
    });
    mutate(form);
  };

  return (
    <SelectModal
      title={'백테스팅을 시작하시겠습니까?'}
      onClose={onClose}
      width={600}
      height={'auto'}
    >
      <div className={styles.root}>
        <ConfirmStartBacktestingModalRow title="전략 이름">
          <span className={styles.title}>{name}</span>
        </ConfirmStartBacktestingModalRow>
        <ConfirmStartBacktestingModalRow title="투자원금">
          <span className={styles.priceText}>
            {formatCurrency(parseNumberFormatString(principal) * 10000)}원
          </span>
        </ConfirmStartBacktestingModalRow>
        <ConfirmStartBacktestingModalRow
          title="백테스팅 기간"
          extraClassName={styles.backtestingRangeContainer}
        >
          <div className={styles.backtestingRangeWrapper}>
            <span className={styles.backtestingRange}>
              {`${startDate} ${startTime} ~ ${endDate} ${endTime}`}
            </span>
            <TextHighlight
              text={`총 ${dayGap}일 ${hourGap}시간 ${minuteGap}분`}
            />
          </div>
        </ConfirmStartBacktestingModalRow>

        <div className={styles.buttonWrapper}>
          <Button onClick={handleCancelClick} theme="neutral3" size="medium">
            취소하기
          </Button>
          <Button onClick={handleStartClick} theme="primary1" size="medium">
            시작하기
          </Button>
          {id && (
            <Button
              onClick={handleStartWithEditClick}
              theme="primary1"
              size="medium"
            >
              원본 수정하기
            </Button>
          )}
        </div>
      </div>
    </SelectModal>
  );
};

export default ConfirmStartBacktestingModal;
