import { logicalOperators, LogicalOperator } from 'constants/';

import { StrategyExprs, Formula } from 'features/api/chart/strategy/type';

export const convertExprsToStrategyText = (exprs: StrategyExprs) => {
  return exprs.reduce<string>((acc, expr) => {
    if (logicalOperators.includes(expr as LogicalOperator)) {
      return `${acc} ${expr}`;
    }

    const { name } = expr as Formula;

    return `${acc} ${name}`;
  }, '');
};

export const convertExprsToStrategy = (
  exprs: StrategyExprs,
): Array<string | LogicalOperator> => {
  if (exprs.length === 0) {
    return [];
  }

  return exprs.reduce<Array<string | LogicalOperator>>((acc, expr) => {
    if (logicalOperators.includes(expr as LogicalOperator)) {
      return [...acc, expr as string];
    }

    const { name } = expr as Formula;

    return [...acc, name];
  }, []);
};
