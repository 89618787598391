import { useCallback } from 'react';

import { AVAILABLE_MARKETS, indicatorInfos, MarketName } from 'constants/';
import { useAppDispatch } from 'store';

import { MyStrategyDetail } from 'features/api/chart/strategy/type';
import { Market, MarketIndicatorInfo } from 'features/schemas/client';
import { importSettings } from 'features/slices';

import ConfirmFormulaModal from 'components/Modal/ConfirmFormulaModal';
import ConfirmStartBacktestingModal from 'components/Modal/ConfirmStartBacktestingModal';
import ImportStrategyModal from 'components/Modal/ImportStrategyModal/ImportStrategyModal';
import {
  createModalContext,
  RenderModalsProps,
} from 'components/Modal/ModalProvider';
import { ModalKind } from 'components/Modal/ModalTypes';
import PreviewInProgressModal from 'components/Modal/PreviewInProgressModal/PreviewInProgressModal';
import SelectIndicatorModal from 'components/Modal/SelectIndicatorModal/SelectIndicatorModal';
import SelectMarketModal from 'components/Modal/SelectMarketModal/SelectMarketModal';

import { ImportStrategyCallback, MarketIndicatorInfoCallback } from './type';

type StrategyPageModalArgs = {
  initialMarket?: Market | null;
  callback?: MarketIndicatorInfoCallback | ImportStrategyCallback;
  isBuy?: boolean;
};

type StrategyPageModalsState = {
  args?: StrategyPageModalArgs;
  selectedMarket: MarketName;
  marketIndicatorInfo: MarketIndicatorInfo | undefined;
};

const StrategyPageModals = ({
  hideModal,
  context,
  setContext,
}: RenderModalsProps<StrategyPageModalsState>) => {
  const args = context.args as StrategyPageModalArgs;
  const dispatch = useAppDispatch();
  const indicatorAndMarketInfo = {
    markets: AVAILABLE_MARKETS,
    indicators: indicatorInfos,
  };

  const onMarketSelect = (selectedMarket: MarketName) => {
    setContext((prevState) => {
      return {
        ...prevState,
        selectedMarket,
      };
    });
    hideModal();
  };

  const onIndicatorInfoSelect = (marketIndicatorInfo: MarketIndicatorInfo) => {
    if (args?.callback) {
      (args?.callback as MarketIndicatorInfoCallback)(marketIndicatorInfo);
    } else {
      setContext((prevState) => {
        return {
          ...prevState,
          marketIndicatorInfo: undefined,
        };
      });
    }
    hideModal();
  };

  const handleStrategySelect = useCallback(
    async (strategy: MyStrategyDetail) => {
      if (args?.callback) {
        (args?.callback as ImportStrategyCallback)(strategy);
      } else {
        dispatch(importSettings(strategy));
      }
      hideModal();
    },
    [args?.callback, dispatch, hideModal],
  );

  const onFormulaResetClick = () => {
    hideModal();
  };

  const onStartBacktestingClick = () => {
    hideModal();
  };

  const onSubmitSelectIndicator = () => {
    setContext((prevState) => {
      return {
        ...prevState,
        marketIndicatorInfo: undefined,
      };
    });
    hideModal();
  };

  return [
    <ConfirmFormulaModal
      key={ModalKind.ConfirmFormulaModal}
      kind={ModalKind.ConfirmFormulaModal}
      onSubmit={onFormulaResetClick}
      onClose={hideModal}
    />,
    <ConfirmStartBacktestingModal
      key={ModalKind.ConfirmStartBacktestingModal}
      kind={ModalKind.ConfirmStartBacktestingModal}
      onSubmit={onStartBacktestingClick}
      onClose={hideModal}
    />,
    <SelectMarketModal
      key={ModalKind.SelectMarketModal}
      kind={ModalKind.SelectMarketModal}
      onClose={hideModal}
      onSelect={onMarketSelect}
    />,
    <SelectIndicatorModal
      key={ModalKind.SelectIndicatorModal}
      kind={ModalKind.SelectIndicatorModal}
      indicatorContext={context.marketIndicatorInfo}
      onClose={hideModal}
      onSelect={onIndicatorInfoSelect}
      indicatorAndMarketInfo={indicatorAndMarketInfo}
      excludedIndicatorKrNames={['현재가', '평균매수가격', '최초매수가격']}
      onSubmit={onSubmitSelectIndicator}
    />,
    <ImportStrategyModal
      key={ModalKind.ImportStrategyModal}
      kind={ModalKind.ImportStrategyModal}
      onClose={hideModal}
      onSelect={handleStrategySelect}
      mode="backtesting"
    />,
    <PreviewInProgressModal
      key={ModalKind.PreviewInProgressModal}
      kind={ModalKind.PreviewInProgressModal}
      onClose={hideModal}
      isBuy={args?.isBuy}
    />,
  ];
};

const { ModalProvider, useModal } = createModalContext<
  StrategyPageModalsState,
  StrategyPageModalArgs
>(
  {
    args: undefined,
    selectedMarket: 'KRW-BTC',
    marketIndicatorInfo: undefined,
  },
  StrategyPageModals,
);

export {
  ModalProvider as StrategyPageModalsProvider,
  useModal as useStrategyPageModal,
};

// 최초의 함수 선택 모달은 마켓타이밍 가능
// 함수 안의 항목으로 지표 혹은 함수를 다시 선택하면 이때는 마켓타이밍 불가능
// 근데 ()는 예외
