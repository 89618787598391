import { useState } from 'react';

import {
  indicatorArgsMap,
  IndicatorInfo,
  IndicatorInputInfo,
  IndicatorInputLabel,
} from 'constants/';
import { z } from 'zod';

import { Market, MarketIndicatorInfo } from 'features/schemas/client';

import Button from 'components/Button';
import IndicatorDropdown from 'components/Dropdown/IndicatorDropdown';

import NumberInputField from 'components/Input/NumberInputWithLabel';
import { SelectValueModalProps } from 'components/Modal/ModalTypes';

import SelectModal from 'components/Modal/SelectModal/SelectModal';

import styles from 'components/Modal/SelectIndicatorModal/SelectIndicatorModal.module.scss';

interface IndicatorAndMarketInfoList {
  markets: Market[];
  indicators: IndicatorInfo[];
}

type SelectIndicatorModalProps = SelectValueModalProps<MarketIndicatorInfo> & {
  title?: string;
  indicatorAndMarketInfo: IndicatorAndMarketInfoList;
  excludedIndicatorKrNames?: string[];
  indicatorContext: MarketIndicatorInfo | undefined;
};

const getIndicatorInputs = (
  indicator: IndicatorInfo | null,
): IndicatorInputInfo[] => {
  if (!indicator) {
    return [];
  }

  const namePrefix = indicator.name.split('/')[0];
  return indicatorArgsMap[namePrefix] || [];
};

const INDICATOR_ARGS_MIN_VALUE = 1;
const INDICATOR_ARGS_MAX_VALUE = 200;
const SHIFT_MAX_VALUE = 100;
const STD_DEV_MAX_VALUE = 50;

const SelectIndicatorModal = ({
  title,
  indicatorAndMarketInfo,
  excludedIndicatorKrNames,
  indicatorContext,
  onClose,
  onSelect,
}: SelectIndicatorModalProps) => {
  const [selectedIndicator, setSelectedIndicator] =
    useState<IndicatorInfo | null>(indicatorContext?.indicator ?? null);

  const isValid = Boolean(selectedIndicator);

  const getInitialIndicatorArgs = (selectedIndicator: IndicatorInfo | null) => {
    const inputInfos = getIndicatorInputs(selectedIndicator);

    return inputInfos
      ? inputInfos.reduce<{ [key in IndicatorInputLabel]?: number }>(
          (acc, info) => {
            acc[info.label] = info.defaultValue;
            return acc;
          },
          {},
        )
      : null;
  };

  const indicatorInputInfos: IndicatorInputInfo[] =
    getIndicatorInputs(selectedIndicator);

  const convertArgsArrayToObj = (args: number[]) => {
    if (!indicatorInputInfos) {
      return null;
    }
    return indicatorInputInfos.reduce<{
      [key in IndicatorInputLabel]?: number;
    }>((acc, info, index) => {
      acc[info.label] = args[index];
      return acc;
    }, {});
  };

  const shiftSchema = z
    .number()
    .min(INDICATOR_ARGS_MIN_VALUE)
    .max(SHIFT_MAX_VALUE);

  const stdDevSchema = z
    .number()
    .min(INDICATOR_ARGS_MIN_VALUE)
    .max(STD_DEV_MAX_VALUE)
    .transform((value) => {
      const integerPart = Math.floor(value);
      const decimalPart = value.toString().split('.')[1];
      return decimalPart
        ? parseFloat(`${integerPart}.${decimalPart.slice(0, 4)}`)
        : value;
    });

  const indicatorArgsSchema = z
    .number()
    .min(INDICATOR_ARGS_MIN_VALUE)
    .max(INDICATOR_ARGS_MAX_VALUE);

  const [shift, setShift] = useState<number>(indicatorContext?.shift ?? 1);
  const initialArgsObj = indicatorContext?.args
    ? convertArgsArrayToObj(indicatorContext?.args)
    : getInitialIndicatorArgs(selectedIndicator);
  const [indicatorArgs, setIndicatorArgs] = useState<
    { [key in IndicatorInputLabel]?: number } | null
  >(initialArgsObj);

  const handleNthCandleChange = (value: number) => {
    const parseResult = shiftSchema.safeParse(value);

    if (!parseResult.success) {
      const adjustedValue = value > 100 ? 100 : 1;
      setShift(adjustedValue);
      return;
    }

    setShift(Math.floor(value));
  };

  const handleIndicatorArgChange = (
    label: IndicatorInputLabel,
    value: number,
  ) => {
    if (label === '표준편차 계수') {
      const parseResult = stdDevSchema.safeParse(value);
      setIndicatorArgs((prev) => ({
        ...prev,
        [label]: parseResult.success
          ? parseResult.data
          : value < INDICATOR_ARGS_MIN_VALUE
          ? INDICATOR_ARGS_MIN_VALUE
          : STD_DEV_MAX_VALUE,
      }));
      return;
    }

    const parseResult = indicatorArgsSchema.safeParse(value);
    setIndicatorArgs((prev) => ({
      ...prev,
      [label]: parseResult.success
        ? Math.floor(parseResult.data)
        : value < INDICATOR_ARGS_MIN_VALUE
        ? INDICATOR_ARGS_MIN_VALUE
        : INDICATOR_ARGS_MAX_VALUE,
    }));
  };

  const handleIndicatorSelect = (indicator: IndicatorInfo) => {
    setSelectedIndicator(indicator);
    setShift(1); // 다른 지표를 선택하면 다시 1봉전이 되어야 한다
    // selectedIndicator.name 가 달라지면 value 를 defaultValue 로 변경

    // TB-4623
    setIndicatorArgs(null);

    const defaultParams = getIndicatorInputs(indicator);
    defaultParams.forEach((param) => {
      setIndicatorArgs((prev) => ({
        ...prev,
        [param.label]: param.defaultValue,
      }));
    });
  };

  const onSubmit = () => {
    // SelectValueModalProps onSubmit에 context를 초기화 하도록 했다

    if (isValid) {
      const baseArgs = getInitialIndicatorArgs(selectedIndicator);
      const finalArgs = indicatorArgs
        ? { ...baseArgs, ...indicatorArgs }
        : baseArgs;

      onSelect({
        indicator: selectedIndicator!,
        shift,
        args: finalArgs ? Object.values(finalArgs) : [],
      });
    }
  };

  return (
    <SelectModal
      title={title || '포뮬라에 사용할 지표를 선택하세요'}
      onClose={onClose}
      width={480}
      height={'auto'}
    >
      <div className={styles.root}>
        <div className={styles.selectorWrapper}>
          <div className={styles.selectorTitle}>지표</div>
          <IndicatorDropdown
            value={selectedIndicator}
            setValue={handleIndicatorSelect}
            indicators={indicatorAndMarketInfo.indicators}
            excludedIndicatorKrNames={excludedIndicatorKrNames}
          />
        </div>
        <div className={styles.inputsContainer}>
          {selectedIndicator && (
            <NumberInputField
              label={'봉전'}
              value={shift}
              onChange={handleNthCandleChange}
            />
          )}
          {indicatorInputInfos.map((inputInfo) => (
            <NumberInputField
              key={inputInfo.label}
              label={inputInfo.label}
              value={
                indicatorArgs
                  ? indicatorArgs[inputInfo.label]
                  : inputInfo.defaultValue
              }
              onChange={(value) =>
                handleIndicatorArgChange(inputInfo.label, value)
              }
            />
          ))}
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            onClick={onSubmit}
            isClickable={isValid}
            theme="primary1"
            size="medium"
            isFullWidth
          >
            완료
          </Button>
        </div>
      </div>
    </SelectModal>
  );
};

export default SelectIndicatorModal;
