import { Tooltip } from 'react-tooltip';

import styles from './index.module.scss';
import colors from 'styles/constants/_colors.module.scss';

interface RatelTooltipProps {
  id: string;
  title: string;
  description?: string;
  descriptionTag?: React.ReactNode;
  example?: string;
  children?: React.ReactNode;
  place?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end';
}

const RatelTooltip = ({
  id,
  title,
  description,
  descriptionTag,
  example,
  children,
  place = 'bottom-start',
}: RatelTooltipProps) => {
  return (
    <Tooltip
      id={id}
      place={place}
      className={styles.root}
      style={{
        backgroundColor: colors.n80,
        zIndex: 9999,
      }}
    >
      <dl>
        <dt className={styles.title}>{title}</dt>
        {description && (
          <dd key={description} className={styles.description}>
            {description}
          </dd>
        )}
        {descriptionTag && (
          <dd key={description} className={styles.description}>
            {descriptionTag}
          </dd>
        )}
      </dl>
      <p className={styles.example}>{example}</p>
      {children}
    </Tooltip>
  );
};

export default RatelTooltip;
