import { useRouter } from 'next/router';

import { useEffect, useState } from 'react';

import { routes } from 'constants/';
import { useSetAtom } from 'jotai';
import { useSession } from 'next-auth/react';

import { edittingRobotAtom } from 'store/atoms';

import strategyQuery from 'hooks/useQuery/strategy';

import strategyApi from 'features/api/chart/strategy';

import {
  MyStrategyDetail,
  MyStrategyOverview,
  PublicStrategyDetail,
  PublicStrategyOverview,
} from 'features/api/chart/strategy/type';

import Button from 'components/Button';

import ConfirmStrategyImportModal from 'components/Modal/ConfirmStrategyImportModal/ConfirmStrategyImportModal';
import {
  ImportStrategyModalHeaderRow,
  ImportStrategyModalRow,
} from 'components/Modal/ImportStrategyModal/ImportStrategyModalRow';
import { ModalKind, SelectValueModalProps } from 'components/Modal/ModalTypes';

import SelectModal from 'components/Modal/SelectModal/SelectModal';

import Spinner from 'components/Spinner';

import styles from 'components/Modal/ImportStrategyModal/ImportStrategyModal.module.scss';

type ImportStrategyModalProps = Omit<
  SelectValueModalProps<MyStrategyDetail | PublicStrategyDetail>,
  'onSubmit'
>;

type ImportBoardStrategyModalProps = ImportStrategyModalProps & {
  forBoard?: boolean;
  mode: 'backtesting' | 'robot';
};

const ImportStrategyModal = ({
  onSelect,
  onClose,
  mode, // true면 로봇 생성 모드
}: ImportBoardStrategyModalProps) => {
  const router = useRouter();
  const { data: session } = useSession();
  const [strategyId, setStrategyId] = useState<string | undefined>(undefined);
  // 로봇 생성 시 필요
  const [edittingRobotStrategyId, setEdittingRobotStrategyId] = useState<
    string | undefined
  >(undefined);

  const { isLoading: isStrategyDetailLoading, data: strategyDetail } =
    strategyQuery.useStrategy({
      strategyId: strategyId || '',
      options: {
        enabled: Boolean(strategyId),
      },
    });

  const {
    isLoading,
    isSuccess,
    data: strategyList = [],
  } = strategyQuery.useAllStrategies({
    username: session?.username,
  });

  const setEdittingRobot = useSetAtom(edittingRobotAtom);

  const strategyListForBacktesting = strategyList.filter(
    (strategy) => strategy.author === session?.username,
  );

  useEffect(() => {
    if (strategyDetail && !Boolean(strategyDetail.ror)) {
      onSelect(strategyDetail);
    }
  }, [onSelect, strategyDetail]);

  useEffect(() => {
    (async () => {
      if (!edittingRobotStrategyId) {
        return;
      }

      if (
        strategyListForBacktesting.find(
          (x) => x.strategy_id === edittingRobotStrategyId,
        )
      ) {
        const detail = await strategyApi.readStrategy(edittingRobotStrategyId);
        if (detail) {
          setEdittingRobot({
            strategy_id: detail?.strategy_id,
            name: detail?.name,
            market: detail?.markets[0],
          });
        }
        onClose();
        return;
      }

      const detail = await strategyApi.readPublicStrategy(
        edittingRobotStrategyId,
      );

      if (detail) {
        setEdittingRobot({
          strategy_id: detail?.strategy_id,
          name: detail?.name,
          market: detail?.markets[0],
        });
      }
      onClose();
    })();
  }, [
    edittingRobotStrategyId,
    onClose,
    setEdittingRobot,
    strategyListForBacktesting,
  ]);

  const handleStrategySelect = async (
    strategy: MyStrategyOverview | PublicStrategyOverview,
  ) => {
    if (isStrategyDetailLoading && strategyId! && mode === 'backtesting') {
      return;
    }

    // 로봇 생성 시
    if (mode === 'robot') {
      setEdittingRobotStrategyId(strategy.strategy_id);
      return;
    }

    setStrategyId(strategy.strategy_id);
  };

  const handleCancel = () => {
    setStrategyId(undefined);
  };

  const handleConfirm = (strategy: MyStrategyDetail | PublicStrategyDetail) => {
    onSelect(strategy);
  };

  const handleMarketplaceClick = () => {
    router.push(routes.marketplaceRoute);
  };

  return (
    <SelectModal
      title={
        mode === 'robot'
          ? '자동매매 전략을 선택해주세요'
          : '불러올 전략을 선택해 주세요'
      }
      onClose={onClose}
      width={1000}
      height={'auto'}
    >
      {strategyDetail && Boolean(strategyDetail.ror) && (
        <div className={styles.confirmModalContainer}>
          <ConfirmStrategyImportModal
            kind={ModalKind.ConfirmStrategyImportModal}
            strategy={strategyDetail}
            onClose={handleCancel}
            onConfirm={handleConfirm}
          />
        </div>
      )}
      <div className={styles.root}>
        <div className={styles.rowContainer}>
          <ImportStrategyModalHeaderRow />
          {isLoading ? (
            <div className={styles.spinnerContainer}>
              <Spinner />
            </div>
          ) : (
            <div className={styles.rowListWrapper}>
              {(mode === 'robot'
                ? strategyList
                : strategyListForBacktesting
              ).map((strategy) => {
                return (
                  <ImportStrategyModalRow
                    key={strategy.id}
                    strategy={strategy}
                    handleSelect={() => handleStrategySelect(strategy)}
                  />
                );
              })}
            </div>
          )}
        </div>
        {isSuccess && mode === 'robot' && strategyList.length === 0 && (
          <div className={styles.noResult}>
            <div>
              <p>백테스팅이 완료된 전략이 없어요.</p>
              <p>전문 퀀트 트레이더가 올린 전략을 구경해보실래요?</p>
            </div>
            <div>
              <Button
                theme="primary1"
                size="large"
                onClick={handleMarketplaceClick}
              >
                전략 구경하러 가기
              </Button>
            </div>
          </div>
        )}
        <div></div>
      </div>
    </SelectModal>
  );
};

export default ImportStrategyModal;
