import clsx from 'clsx';
import RadioSelectedIcon from 'images/icons/svgs/radio-selected.svg';
import RadioUnselectedIcon from 'images/icons/svgs/radio-unselected.svg';

import styles from './index.module.scss';

interface RadioProps {
  isSelected: boolean;
  onChange: () => void;
  title: string;
  isLabelVisible?: boolean;
  disabled?: boolean;
}

const Radio = ({
  isSelected,
  onChange,
  title,
  isLabelVisible = true,
  disabled = false,
}: RadioProps) => {
  const handleChange = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();

    if (disabled) {
      return;
    }

    onChange();
  };

  return (
    <div
      className={clsx(styles.radio, disabled && styles.disabled)}
      onClick={handleChange}
    >
      <div className={styles.input}>
        <input
          type="radio"
          id={title}
          className={styles.input}
          checked={isSelected}
          // TODO: Implement onChange, 지금은 겁나 weird한 방식으로 구현되어 있음
          onChange={() => {}}
        />
        {isSelected ? (
          <RadioSelectedIcon viewBox="0 0 25 24" />
        ) : (
          <RadioUnselectedIcon viewBox="0 0 25 24" />
        )}
      </div>
      {isLabelVisible && (
        <label
          htmlFor={title}
          className={clsx(styles.title, isSelected && styles.selected)}
        >
          {title}
        </label>
      )}
    </div>
  );
};

export default Radio;
