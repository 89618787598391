import {
  tradingViewCandleToCandleKindMap,
  TradingViewCandleKind,
} from 'constants/';

import { addBuyStrategyToPreviewExprs } from 'features/adaptor/converters';
import {
  BacktestingPreviewForm,
  PreviewKind,
} from 'features/api/chart/backtesting/type';

import { ChartData, BacktestingSettingState } from 'features/slices';

const convertChartSymbolToPreviewMarket = (symbol: string): string => {
  const market = symbol.split(':')[1];
  const [code, currency] = market.split('/');
  return `${currency}-${code}`;
};
interface PreviewFormProps {
  kind: PreviewKind;
  chartState: ChartData;
  backtestingSettingState: BacktestingSettingState;
}

export const convertStateToPreviewForm = ({
  kind,
  chartState,
  backtestingSettingState,
}: PreviewFormProps): BacktestingPreviewForm => {
  const { name, buyFormulas, sellFormulas, conditionExpression, sellConfig } =
    backtestingSettingState;
  const market = convertChartSymbolToPreviewMarket(chartState.symbol);
  const currentTimestamp = Date.now();
  let endTimestamp = chartState.visibleRange
    ? chartState.visibleRange!.to * 1000
    : 0;
  if (currentTimestamp < endTimestamp) {
    endTimestamp = currentTimestamp;
  }
  const startDate = chartState.visibleRange
    ? new Date(chartState.visibleRange!.from * 1000)
        .toISOString()
        .replace('Z', '')
    : new Date().toISOString().replace('Z', '');
  const endDate = new Date(endTimestamp).toISOString().replace('Z', '');
  const chartCandleKind =
    tradingViewCandleToCandleKindMap[chartState.unit as TradingViewCandleKind];

  return {
    name,
    strategy: {
      name,
      buy_exprs: addBuyStrategyToPreviewExprs(
        buyFormulas,
        conditionExpression.buyStrategy,
        chartCandleKind,
      ),
      sell_exprs: addBuyStrategyToPreviewExprs(
        sellFormulas,
        conditionExpression.sellStrategy,
        chartCandleKind,
      ),
      profit_cut: sellConfig.profit_cut,
      loss_cut: sellConfig.loss_cut,
      market_max_holding_minutes: sellConfig.market_max_holding_minutes,
    },
    config: {
      start: startDate,
      end: endDate,
      crypto_exchange: 'coinone',
      market,
    },
    preview: kind,
  };
};
