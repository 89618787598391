import { createContext, useRef } from 'react';

export const ScrollContext = createContext<{
  handleParentElementScroll: (e: any) => void;
  resetScrollPos: () => void;
  registerElement: (element: HTMLElement, id: string) => void;
  deleteElement: (id: string) => void;
}>({
  handleParentElementScroll: () => {},
  resetScrollPos: () => {},
  registerElement: () => {},
  deleteElement: () => {},
});

type ScrollContextProviderProps = {
  scrollType: 'scrollLeft' | 'scrollTop';
} & React.PropsWithChildren;

export const ScrollContextProvider = ({
  scrollType,
  children,
}: ScrollContextProviderProps) => {
  const scrollSubscribedElements = useRef<Record<string, HTMLElement>>({});
  const scrollPos = useRef(0);

  const updateScrollPosOfSubscribedElements = () => {
    const transform = `-${scrollPos.current}px`;
    for (const element of Object.values(scrollSubscribedElements.current)) {
      const originalTransform = element.style.transform;
      const originalTransformX =
        originalTransform?.split(',')[0].split('(')[1] || '0px';
      const originalTransformY =
        originalTransform?.split(',')[1].split(')')[0] || '0px';
      if (scrollType === 'scrollTop') {
        element.style.transform = `translate(${originalTransformX}, ${transform})`;
      } else {
        element.style.transform = `translate(${transform}, ${originalTransformY})`;
      }
    }
  };

  const handleParentElementScroll = (ev: any) => {
    scrollPos.current = ev.target[scrollType];
    updateScrollPosOfSubscribedElements();
  };

  const resetScrollPos = () => {
    scrollPos.current = 0;
    updateScrollPosOfSubscribedElements();
  };

  const registerElement = (element: HTMLElement, id: string) => {
    scrollSubscribedElements.current[id] = element;
  };

  const deleteElement = (id: string) => {
    delete scrollSubscribedElements.current[id];
  };

  return (
    <ScrollContext.Provider
      value={{
        handleParentElementScroll,
        resetScrollPos,
        registerElement,
        deleteElement,
      }}
    >
      {children}
    </ScrollContext.Provider>
  );
};
