import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import { MyStrategyDetail } from 'features/api/chart/strategy/type';

import Button from 'components/Button';
import { SimpleModalProps } from 'components/Modal/ModalTypes';
import SelectModal from 'components/Modal/SelectModal/SelectModal';

import styles from 'components/Modal/ConfirmStrategyImportModal/ConfirmStrategyImportModal.module.scss';

type ConfirmStrategyImportModalProps = {
  strategy?: MyStrategyDetail;
  onConfirm?: (strategy: MyStrategyDetail) => void;
  isEditing?: boolean;
} & Omit<SimpleModalProps, 'onSubmit'>;

const ConfirmStrategyImportModal = ({
  strategy,
  onClose,
  onConfirm,
  isEditing,
}: ConfirmStrategyImportModalProps) => {
  const handleCancelClick = () => {
    onClose();
  };

  if (!strategy) {
    return <div />;
  }

  const handleImportClick = () => {
    if (isEditing) {
      trackEvent(amplitudeEventNames.STRG_EDIT, {
        value: strategy.name,
        ror: strategy.ror,
        mdd: strategy.mdd,
      });
    }

    if (strategy && onConfirm) {
      onConfirm(strategy);
    }
  };

  const title = isEditing
    ? `[${strategy.name}] 전략을 수정하시겠습니까?`
    : `[${strategy.name}] 전략을 불러오시겠습니까?`;

  const buttonText = isEditing ? '수정하기' : '불러오기';

  return (
    <SelectModal title={title} onClose={onClose} width={600} height={'auto'}>
      <div className={styles.root}>
        <div className={styles.description}>
          선택한 전략은 백테스팅이 완료된 전략이에요. <br />
          전략을 불러와서 새로 수정하시겠어요?
        </div>
        <div className={styles.buttonsWrapper}>
          <Button
            theme="primary3"
            size="medium"
            onClick={handleCancelClick}
            extraClassNames={[styles.button]}
          >
            취소하기
          </Button>
          <Button
            theme="primary1"
            size="medium"
            onClick={handleImportClick}
            extraClassNames={[styles.button]}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </SelectModal>
  );
};

export default ConfirmStrategyImportModal;
