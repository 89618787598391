import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import clsx from 'clsx';

import { useClickAway } from 'hooks';
import ArrowDownIcon from 'images/icons/svgs/arrow-down.svg';

import SearchBar from 'components/Input/SearchBar';
import { ScrollContext } from 'components/Scroll/ScrollContext';

import styles from 'components/Dropdown/SearchableDropdown.module.scss';

interface SearchableDropdownProps {
  currentKey: string | null;
  setKey: (v: string) => void;
  categoryToKeysMap: Record<string, string[]>;
  height?: number;
  isEditable?: boolean;
  separateCategory?: boolean;
  renderRow?: (v: string, currentKey: string | null) => React.ReactNode;
  renderSelected?: (v: string) => React.ReactNode;
  noSearch?: boolean;
  dataCy?: string;
  inactiveKeys?: string[];
}

const UNCATEGORIZED = 'UNCATEGORIZED';

interface DropdownPanelProps {
  currentKey: string | null;
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  categoryToKeysMap: Record<string, string[]>;
  handleItemClick: (v: string) => void;
  renderRow?: (v: string, currentKey: string | null) => React.ReactNode;
  noSearch?: boolean;
}

interface OptionRowProps {
  optionKey: string;
  currentKey: string | null;
  handleItemClick: (v: string) => void;
  renderRow?: (v: string, currentKey: string | null) => React.ReactNode;
}

const OptionRow = ({
  optionKey,
  currentKey,
  handleItemClick,
  renderRow,
}: OptionRowProps) => {
  if (renderRow) {
    return (
      <div onClick={() => handleItemClick(optionKey)}>
        {renderRow(optionKey, currentKey)}
      </div>
    );
  }

  return (
    <div
      key={optionKey}
      className={clsx(
        styles.option,
        optionKey === currentKey && styles.selectedOption,
      )}
      onClick={() => handleItemClick(optionKey)}
    >
      {optionKey}
    </div>
  );
};

const CategorySeparatedDropdownPanel = ({
  currentKey,
  searchText,
  setSearchText,
  categoryToKeysMap,
  handleItemClick,
  renderRow,
}: DropdownPanelProps) => {
  const { handleParentElementScroll } = useContext(ScrollContext);
  const listContainerRef = useRef<HTMLDivElement>(null);

  const [selectedCategory, setSelectedCategory] = useState<string>(
    Object.keys(categoryToKeysMap)[0],
  );

  const onCategoryClick = (v: string) => {
    setSelectedCategory(v);
  };

  return (
    <div className={clsx(styles.categorySeparatedPanelRoot)}>
      <div className={styles.categoryList}>
        {Object.keys(categoryToKeysMap).map((category) => {
          return (
            <div
              key={category}
              className={clsx(
                styles.option,
                category === selectedCategory && styles.selectedOption,
              )}
              onClick={() => onCategoryClick(category)}
            >
              {category}
            </div>
          );
        })}
      </div>
      <div
        className={styles.itemList}
        ref={listContainerRef}
        onScroll={handleParentElementScroll}
      >
        <div className={styles.searchBarContainer}>
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            width={280}
          />
        </div>
        {categoryToKeysMap[selectedCategory].map((optionKey) => (
          <OptionRow
            key={optionKey}
            {...{
              optionKey,
              currentKey,
              handleItemClick: handleItemClick,
              renderRow,
            }}
          />
        ))}
      </div>
    </div>
  );
};

const CategoryIntegratedDropdownPanel = ({
  currentKey,
  searchText,
  setSearchText,
  categoryToKeysMap,
  handleItemClick,
  renderRow,
  noSearch,
}: DropdownPanelProps) => {
  return (
    <div className={clsx(styles.optionListContainer)}>
      {!noSearch && (
        <div className={styles.searchBarContainer}>
          <SearchBar searchText={searchText} setSearchText={setSearchText} />
        </div>
      )}
      <div style={{ height: noSearch ? 5 : 15 }} />
      {Object.entries(categoryToKeysMap)
        .map((entry) => {
          const [category, keys] = entry;
          if (keys.length === 0) {
            return null;
          }

          return (
            <div key={category} className={styles.categoryWrapper}>
              {category !== UNCATEGORIZED && (
                <div className={styles.category}>
                  <span>{category}</span>
                </div>
              )}
              {keys.map((optionKey) => {
                return (
                  <OptionRow
                    key={optionKey}
                    {...{
                      optionKey,
                      currentKey,
                      handleItemClick,
                      renderRow,
                    }}
                  />
                );
              })}
            </div>
          );
        })
        .filter((v) => v !== null)}
    </div>
  );
};

const SearchableDropdown = ({
  currentKey,
  setKey,
  categoryToKeysMap,
  height,
  isEditable,
  separateCategory,
  renderRow,
  renderSelected,
  noSearch,
  dataCy,
  inactiveKeys,
}: SearchableDropdownProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const isExpandedRef = useRef(false);
  const [searchText, setSearchText] = useState<string>('');

  const allKeys = useMemo(
    () => Object.values(categoryToKeysMap).flat(),
    [categoryToKeysMap],
  );

  const filteredKeys = useMemo(() => {
    if (searchText === '') {
      return allKeys;
    }
    return allKeys.filter((key) =>
      key.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [allKeys, searchText]);

  const filteredCategoryToKeysMap = useMemo(() => {
    const result: Record<string, string[]> = {};
    Object.entries(categoryToKeysMap).forEach(([category, keys]) => {
      result[category] = keys.filter((key) => filteredKeys.includes(key));
    });

    return result;
  }, [categoryToKeysMap, filteredKeys]);

  const handleItemClick = (v: string) => {
    if (inactiveKeys && inactiveKeys.includes(v)) {
      return;
    }
    setKey(v);
    setIsExpanded(false);
    isExpandedRef.current = false;
  };

  const handleSelectedItemContainerClick = () => {
    if (isEditable) {
      isExpandedRef.current = !isExpandedRef.current;
      setIsExpanded((v) => !v);
    }
  };

  const DropdownPanel = separateCategory
    ? CategorySeparatedDropdownPanel
    : CategoryIntegratedDropdownPanel;

  useEffect(() => {
    setSearchText('');
  }, [isExpanded]);

  const dropdownRef = useClickAway(() => checkParentClick());

  const checkParentClick = () => {
    if (!isExpandedRef.current) {
      return;
    }
    setIsExpanded(false);
    isExpandedRef.current = false;
  };

  return (
    <div className={styles.rootContainer} style={{ height }} data-cy={dataCy}>
      <div
        className={clsx(styles.selected, isEditable && 'cursorPointer')}
        onClick={handleSelectedItemContainerClick}
      >
        <span className={clsx(styles.selectedText, styles.selectedTextPos)}>
          {renderSelected && currentKey
            ? renderSelected(currentKey)
            : currentKey || '선택 없음'}
        </span>
        <ArrowDownIcon viewBox="0 0 24 24" className={styles.dropdownIcon} />
      </div>
      <div ref={dropdownRef}>
        {isExpanded && (
          <DropdownPanel
            categoryToKeysMap={filteredCategoryToKeysMap}
            currentKey={currentKey}
            searchText={searchText}
            setSearchText={setSearchText}
            handleItemClick={handleItemClick}
            renderRow={renderRow}
            noSearch={noSearch}
          />
        )}
      </div>
    </div>
  );
};

export default SearchableDropdown;
