import InfoIcon from 'images/icons/svgs/ic-info.svg';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import styles from './TooltipIcon.module.scss';
interface TooltipIconProps {
  id?: string; // 없으면 아이콘으로써만 사용한다는 뜻, 보통은 바텀시트로 처리하는 경우
}

const TooltipIcon = ({ id }: TooltipIconProps) => {
  const handleClick = () => {
    trackEvent(amplitudeEventNames.BTN_TOOLTIP);
  };

  return (
    <InfoIcon
      data-tooltip-id={id}
      className={styles.root}
      onClick={handleClick}
    />
  );
};

export default TooltipIcon;
