import { IndicatorInfo, indicatorInfos } from 'constants/';

import { StrategyExprs } from 'features/api/chart/strategy/type';
import { Formula } from 'features/schemas/client';

export const validateExprsToFormulas = (exprs: StrategyExprs) => {
  return exprs.reduce<Formula[]>((acc, expr) => {
    if (expr && typeof expr === 'object') {
      const { name, tokens, kind } = expr;
      const tokensWithIndicatorInfo = tokens.map((token) => {
        if (typeof token === 'object') {
          const { name, shift, ...rest } = token;
          const indicatorInfo = indicatorInfos.find(
            (indicator) => indicator.name === name,
          ) as IndicatorInfo;

          return {
            ...rest,
            indicator: indicatorInfo,
            shift: shift + 1,
          };
        } else {
          return token;
        }
      });

      acc.push({
        name,
        tokens: tokensWithIndicatorInfo,
        kind,
      });
    }
    return acc;
  }, []);
};
