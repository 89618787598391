import { ReactNode } from 'react';

import CloseIcon from 'images/icons/svgs/close.svg';

import { SimpleModalProps } from 'components/Modal/ModalTypes';

import styles from './SelectModal.module.scss';

type SelectModalProps = {
  title: string;
  children?: ReactNode;
  width: number;
  height: number | string;
} & Pick<SimpleModalProps, 'onClose'>;

const SelectModal = ({
  onClose,
  title,
  width,
  height,
  children,
}: SelectModalProps) => {
  return (
    <div
      data-cy={'select-modal'}
      className={styles.selectionModal}
      style={{ width, height }}
    >
      <div className={styles.modalTitleWrapper}>
        <span className={styles.modalTitle}>{title}</span>
        <CloseIcon
          onClick={onClose}
          viewBox="0 0 32 32"
          width={24}
          height={24}
          className={styles.closeIcon}
        />
      </div>
      {children}
    </div>
  );
};

export default SelectModal;
