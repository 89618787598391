import { createSlice } from '@reduxjs/toolkit';

interface SelectedData {
  label: string;
  time: number;
  value: number;
}

export interface ChartData {
  symbol: string;
  unit: string;
  selectedData: SelectedData;
  baseDate: string;
  visibleRange: {
    from: number;
    to: number;
  } | null;
  targetVisibleRange: {
    from: number;
    to: number;
  } | null;
}

const initialChartData: ChartData = {
  symbol: 'COINONE:BTC/KRW',
  unit: '1D',
  selectedData: {
    label: '',
    time: 0,
    value: 0,
  },
  baseDate: '',
  visibleRange: null,
  targetVisibleRange: null,
};

export const chartDataSlice = createSlice({
  name: 'chartData',
  initialState: initialChartData,
  reducers: {
    updateSymbolUnit(state, action) {
      const { symbol, unit } = action.payload;
      state.symbol = symbol;
      state.unit = unit;
    },
    updateVisibleRange(state, action) {
      const { visibleRange } = action.payload;
      state.visibleRange = visibleRange;
    },
    updateTargetVisibleRange(state, action) {
      const { targetVisibleRange } = action.payload;
      state.targetVisibleRange = targetVisibleRange;
    },
  },
});

export const {
  updateSymbolUnit,
  updateVisibleRange,
  updateTargetVisibleRange,
} = chartDataSlice.actions;
