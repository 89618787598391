import { useEffect, useState } from 'react';

/**
 * @returns {[number, number]} window width, window height
 */
export const useWindowSize = (): [number, number] => {
  const [size, setSize] = useState<[number, number]>([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    const isFluctuation =
      size[0] !== window.innerWidth || size[1] !== window.innerHeight;

    if (isFluctuation) {
      updateSize();
    }

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, [size]);

  return size;
};
