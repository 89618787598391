import { chartAPI } from 'features/api/chart/base';

import {
  MyStrategyDetail,
  PublicStrategyDetail,
  PublicStrategyForm,
  StrategyChart,
} from './type';

const readAllPublicStrategies = async () => {
  const response = await chartAPI.get('/strategy/public');
  return response.data;
};

const readPublicStrategy = async (
  strategyId: string,
): Promise<PublicStrategyDetail> => {
  const response = await chartAPI.get(`/strategy/public/${strategyId}`);
  return response.data;
};

const makeStrategyPublic = async ({
  strategyId,
  data,
}: {
  strategyId: string;
  data: PublicStrategyForm;
}) => {
  const response = await chartAPI.post(`/strategy/public/${strategyId}`, data);
  return response.data;
};

const makeStrategyPrivate = async (strategyId: string) => {
  const response = await chartAPI.delete(`/strategy/public/${strategyId}`);
  return response;
};

const followStrategy = async (strategyId: string) => {
  const response = await chartAPI.put(
    `/strategy/public/${strategyId}/following`,
  );
  return response;
};

const unfollowStrategy = async (strategyId: string) => {
  const response = await chartAPI.delete(
    `/strategy/public/${strategyId}/following`,
  );
  return response;
};

const followPaperTrading = async (strategyId: string) => {
  const response = await chartAPI.put(`/strategy/public/${strategyId}/paper`);
  return response;
};

const unfollowPaperTrading = async (strategyId: string) => {
  const response = await chartAPI.delete(
    `/strategy/public/${strategyId}/paper`,
  );
  return response;
};

const readAllStrategies = async () => {
  const response = await chartAPI.get('/strategy');
  return response.data;
};

const readStrategy = async (strategyId: string): Promise<MyStrategyDetail> => {
  const response = await chartAPI.get(`/strategy/${strategyId}`);
  return response.data;
};

const deleteStrategy = async (strategyId: string) => {
  await chartAPI.delete(`/strategy/${strategyId}`);
};

const getStrategyChart = async (strategyId: string): Promise<StrategyChart> => {
  const response = await chartAPI.get(`/strategy/${strategyId}/chart`);
  return response.data;
};

export default {
  readAllPublicStrategies,
  readPublicStrategy,
  makeStrategyPublic,
  makeStrategyPrivate,
  followStrategy,
  unfollowStrategy,
  followPaperTrading,
  unfollowPaperTrading,
  readAllStrategies,
  readStrategy,
  deleteStrategy,
  getStrategyChart,
};
