import HeaderLogoIcon from 'images/icons/svgs/logo2.svg';

import styles from 'components/Logo/Logo.module.scss';

const Logo = ({ width = 102, height = 32 }) => {
  return (
    <div className={styles.root}>
      <HeaderLogoIcon viewBox="0 0 205 64" width={width} height={height} />
    </div>
  );
};

export default Logo;
