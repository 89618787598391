import { CaseReducer, createSlice } from '@reduxjs/toolkit';

import { FlagKind } from 'features/env';

type Prefix<P extends string, S extends string> = `${P}${S}`;
type ReactFlagKind = Prefix<'REACT_APP_', FlagKind>;

type FeatureEnableType =
  | { [key in ReactFlagKind]: boolean }
  | Record<string, string>;
interface FeatureToggleState {
  originEnv: FeatureEnableType;
  featureEnv: FeatureEnableType;
  isProduction: boolean;
}

const initialState: FeatureToggleState = {
  originEnv: typeof window !== 'undefined' ? window.__ENV : {},
  featureEnv: typeof window !== 'undefined' ? window.__ENV : {},
  isProduction: false,
};

const _toggleProduction: CaseReducer<FeatureToggleState> = (state) => {
  state.isProduction = !state.isProduction;
  state.isProduction
    ? (state.featureEnv = {})
    : (state.featureEnv = state.originEnv);
};

export const featureToggleSlice = createSlice({
  name: 'featureToggle',
  initialState,
  reducers: {
    toggleProduction: _toggleProduction,
  },
});

export const { toggleProduction } = featureToggleSlice.actions;
