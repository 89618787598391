import { Session } from 'next-auth';

export const request = async <TResponse>(
  url: string,
  config: RequestInit = {},
): Promise<TResponse> => {
  return fetch(url, config)
    .then((response) => response.json())
    .then((data) => data as TResponse);
};

export const refreshSession = async () => {
  const session = await request<Session>('/api/auth/session/?refresh=true');
  return session;
};
