import { AxiosResponse } from 'axios';

import { chartAPI } from 'features/api/chart/base';

import {
  BacktestingForm,
  BacktestingHistory,
  BacktestingPreviewForm,
  BacktestingTaskResponse,
  PreviewTaskResponse,
} from './type';

const readBacktestingTask = async (
  taskId: string,
): Promise<BacktestingTaskResponse> => {
  const response = await chartAPI.get('/backtesting', {
    params: { task_id: taskId },
  });

  return response.data;
};

const revokeTask = async (taskId: string): Promise<void> => {
  return await chartAPI.delete('/backtesting', {
    params: { task_id: taskId },
  });
};

const createBacktestingTask = async (
  data: BacktestingForm,
): Promise<string> => {
  // MEMO: temp, comment, https://timepercent.atlassian.net/browse/TB-4928
  if (data.strategy.sell_exprs && data.strategy.sell_exprs.length === 0) {
    delete data.strategy.sell_exprs;
  }

  const response = await chartAPI.post<string>('/backtesting', data);
  return response.data;
};

const readPreviewTask = async (
  taskId: string,
): Promise<AxiosResponse<PreviewTaskResponse>> => {
  return await chartAPI.get('/backtesting/preview', {
    params: { task_id: taskId },
  });
};

const createBacktestingPreviewTask = async (
  data: BacktestingPreviewForm,
): Promise<string> => {
  const response = await chartAPI.post<string>('/backtesting/preview', data);
  return response.data;
};

const readBacktestingHistoryByBacktestingId = async (
  backtestingId: string,
): Promise<BacktestingHistory> => {
  const response = await chartAPI.get<BacktestingHistory>(
    `/backtesting/${backtestingId}/history`,
  );
  return response.data;
};

export default {
  readBacktestingTask,
  revokeTask,
  createBacktestingTask,
  readPreviewTask,
  createBacktestingPreviewTask,
  readBacktestingHistoryByBacktestingId,
};
