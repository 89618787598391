export type LogicalOperator = 'and' | 'or';
export const logicalOperators = ['and', 'or'];

export enum OperatorKinds {
  And = 'and',
  Or = 'or',
}

export type OperatorTypes = {
  [K in keyof typeof OperatorKinds]: typeof OperatorKinds[K];
}[keyof typeof OperatorKinds];
