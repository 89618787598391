import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { SimpleModalProps } from 'components/Modal/ModalTypes';

const ConfirmFormulaModal = ({ onClose, onSubmit }: SimpleModalProps) => {
  return (
    <ConfirmModal
      onSubmit={onSubmit}
      onClose={onClose}
      title={'포뮬라 초기화'}
      buttonText={'초기화'}
    >
      <p>포뮬라를 초기화하시겠습니까?</p>
      <p>초기화하시면 모든 작성 내용이 사라집니다.</p>
    </ConfirmModal>
  );
};

export default ConfirmFormulaModal;
