import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PreviewTaskResponse } from 'features/api/chart/backtesting/type';

import { TaskStateKind } from 'features/api/chart/constants';

type PreviewOutPut = {
  buy: string[];
  sell: string[];
};

export interface PreviewState {
  taskId: string | undefined;
  output: PreviewOutPut;
  state: TaskStateKind | undefined;
}

export interface InitialPreviewStateInterface {
  buy: PreviewState;
  sell: PreviewState;
}

export const isAvailablePreview = (state: PreviewState) => {
  return state.state === TaskStateKind.Success;
};

export const initialPreviewState: InitialPreviewStateInterface = {
  buy: {
    output: {
      buy: [],
      sell: [],
    },
    state: undefined,
    taskId: undefined,
  },
  sell: {
    output: {
      buy: [],
      sell: [],
    },
    state: undefined,
    taskId: undefined,
  },
};

const _cancelPreview: CaseReducer<InitialPreviewStateInterface> = () => {
  return { ...initialPreviewState };
};

const _setBuyPreviewTaskId: CaseReducer<
  InitialPreviewStateInterface,
  PayloadAction<string>
> = (state, action) => {
  const taskId = action.payload;
  state.buy.taskId = taskId;
  state.buy.state = undefined;
  // reset sell preview
  state.sell.taskId = undefined;
  state.sell.output = {
    buy: [],
    sell: [],
  };
  state.sell.state = undefined;
};

const _setBuyPreviewData: CaseReducer<
  InitialPreviewStateInterface,
  PayloadAction<PreviewTaskResponse>
> = (state, action) => {
  const buyData = action.payload;
  state.buy.output = buyData.output;
  state.buy.state = TaskStateKind.Success;
};

const _setSellPreviewTaskId: CaseReducer<
  InitialPreviewStateInterface,
  PayloadAction<string>
> = (state, action) => {
  const taskId = action.payload;
  state.sell.taskId = taskId;
  state.sell.state = undefined;
  // reset buy preview
  state.buy.taskId = undefined;
  state.buy.output = {
    buy: [],
    sell: [],
  };
  state.buy.state = undefined;
};

const _setSellPreviewData: CaseReducer<
  InitialPreviewStateInterface,
  PayloadAction<PreviewTaskResponse>
> = (state, action) => {
  const sellOutput = action.payload;
  state.sell.output = sellOutput.output;
  state.sell.state = TaskStateKind.Success;
};

export const previewSlice = createSlice({
  name: 'preview',
  initialState: initialPreviewState,
  reducers: {
    cancelPreview: _cancelPreview,
    setBuyPreviewTaskId: _setBuyPreviewTaskId,
    setBuyPreviewData: _setBuyPreviewData,
    setSellPreviewTaskId: _setSellPreviewTaskId,
    setSellPreviewData: _setSellPreviewData,
  },
});

export const {
  cancelPreview,
  setBuyPreviewTaskId,
  setBuyPreviewData,
  setSellPreviewTaskId,
  setSellPreviewData,
} = previewSlice.actions;
