import { MarketName } from 'constants/';
import { atom } from 'jotai';

import { Tradingrobot } from 'features/api/chart/trading/type';

interface EdittingRobot {
  strategy_id: string;
  name: string;
  market: MarketName;
}

const initialEdittingRobot: EdittingRobot | null = null;
const initialStartingRobot: Tradingrobot | null = null;
const initialStoppingRobot: Tradingrobot | null = null;

export const edittingRobotAtom = atom<EdittingRobot | null>(
  initialEdittingRobot,
);

export const startingRobotAtom = atom<Tradingrobot | null>(
  initialStartingRobot,
);

export const stoppingRobotAtom = atom<Tradingrobot | null>(
  initialStoppingRobot,
);
