import clsx from 'clsx';

import styles from 'components/Textbox/TextHighlight.module.scss';

interface TextHighlightProps {
  text: string | number;
  theme?: 'primary' | 'secondary' | 'neutral';
  size?: 'small' | 'medium';
}

const TextHighlight = ({
  text,
  theme = 'primary',
  size = 'medium',
}: TextHighlightProps) => {
  return (
    <mark className={clsx(styles.text, styles[theme], styles[size])}>
      <span>{text}</span>
    </mark>
  );
};

export default TextHighlight;
