import axios from 'axios';
import { getSession } from 'next-auth/react';

import { refreshSession } from 'features/api/refresh';
import { env } from 'features/env';
import { fireAlert } from 'features/utils';

const BASE_URL = `${env.CHART_SERVICE_HOSTNAME}/api/v1/chart`;

export const chartAPI = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

chartAPI.interceptors.request.use(async (config) => {
  const session = await getSession();
  if (session) {
    if (!config.headers) {
      config.headers = {};
    }

    const { accessToken, tokenType } = session;
    config.headers.Authorization = `${tokenType} ${accessToken}`;
  }

  return config;
});

chartAPI.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (!Boolean(error) || !Boolean(error.response)) {
      return Promise.reject(error);
    }

    if (
      (error.response.status === 400 ||
        error.response.status === 401 ||
        error.response.status === 403 ||
        error.response.data === 'Invalid authentication credentials') &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;

      const session = await refreshSession();
      if (session) {
        const { accessToken, tokenType } = session;
        originalRequest.headers.Authorization = `${tokenType} ${accessToken}`;

        return axios(originalRequest);
      }
    }
    if (error.response.status === 429) {
      fireAlert({ text: '이전 요청을 처리중입니다. 잠시 후 시도해주세요' });
      return;
    }

    console.debug(error);
    return Promise.reject(error);
  },
);
