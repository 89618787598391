export enum TaskStateKind {
  Failure = 'FAILURE',
  Pending = 'PENDING',
  Received = 'RECEIVED',
  Retry = 'RETRY',
  Revoked = 'REVOKED',
  Started = 'STARTED',
  Success = 'SUCCESS',
  Progress = 'PROGRESS',
}
