import { addStrategyToFormulaExprs } from 'features/adaptor/converters/';
import { BacktestingForm } from 'features/api/chart/backtesting/type';
import { BacktestingSettingState } from 'features/slices';
import { parseNumberFormatString } from 'features/utils';

export const convertStateToBacktestingForm = (
  backtestingSettingState: BacktestingSettingState,
): BacktestingForm => {
  const {
    startAndEndDate,
    startAndEndTime,
    exchange,
    market,
    principal,
    buyFormulas,
    sellFormulas,
    conditionExpression,
    sellConfig,
    name,
  } = backtestingSettingState;

  const { buyStrategy, sellStrategy } = conditionExpression;
  const [startDate, endDate] = startAndEndDate;
  const [startTime, endTime] = startAndEndTime;
  const start = `${startDate}T${startTime}:00`;
  const end = `${endDate}T${endTime}:00`;

  return {
    name,
    start,
    end,
    crypto_exchange: exchange,
    principal: parseNumberFormatString(principal) * 10_000,
    markets: [market],
    strategy: {
      name,
      buy_exprs: addStrategyToFormulaExprs(buyFormulas, buyStrategy),
      sell_exprs: addStrategyToFormulaExprs(sellFormulas, sellStrategy),
      profit_cut: sellConfig.profit_cut,
      loss_cut: sellConfig.loss_cut,
      market_max_holding_minutes: sellConfig.market_max_holding_minutes || 0,
    },
  };
};
