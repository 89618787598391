import Link from 'next/link';

import React from 'react';

import { policy, footerItem } from 'constants/';

import styles from 'components/Footer/Footer.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contentsWrapper}>
        <div>(주) 타임퍼센트</div>
        <div className={styles.info}>
          {footerItem.map(({ title, info }) => (
            <div key={title}>
              <span>{title}</span>
              <span>&nbsp;:&nbsp;</span>
              <span>{info}</span>
            </div>
          ))}
        </div>
        <div className={styles.policyWrapper}>
          {policy.map((option) => {
            return (
              <React.Fragment key={option.title}>
                <Link href={option.link}>
                  <a target="_blank" rel="noreferrer">
                    <div>
                      {option.title === 'Privacy Policy' ? (
                        <b>{option.title}</b>
                      ) : (
                        <span>{option.title}</span>
                      )}
                    </div>
                  </a>
                </Link>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
