import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import {
  chartDataSlice,
  featureToggleSlice,
  previewSlice,
  backtestingSettingSlice,
} from 'features/slices';

export const store = configureStore({
  reducer: {
    chartData: chartDataSlice.reducer,
    backtestingSetting: backtestingSettingSlice.reducer,
    preview: previewSlice.reducer,
    featureToggle: featureToggleSlice.reducer,
  },
});

const rootReducer = combineReducers({
  chartData: chartDataSlice.reducer,
  backtestingSetting: backtestingSettingSlice.reducer,
  preview: previewSlice.reducer,
  featureToggle: featureToggleSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
type AppDispatch = typeof store.dispatch;

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
