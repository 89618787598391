import clsx from 'clsx';
import SearchIcon from 'images/icons/svgs/search.svg';

import styles from 'components/Input/SearchBar.module.scss';

interface SearchBarProps {
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  width?: number | string;
}

const SearchBar = ({
  searchText,
  setSearchText,
  placeholder,
  width,
}: SearchBarProps) => {
  const hasText = searchText && searchText.length > 0;
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  return (
    <div
      className={clsx(
        styles.inputContainer,
        hasText && styles.textedInputContainer,
      )}
      style={{ width }}
    >
      {!hasText && <SearchIcon className={styles.icon} />}
      <input
        className={clsx(styles.input, hasText && styles.textedInput)}
        value={searchText}
        onChange={handleInputChange}
        placeholder={placeholder || '검색'}
        style={{ width: typeof width === 'number' ? width - 20 : width }}
      />
    </div>
  );
};

export default SearchBar;
