import { useEffect } from 'react';

import loadingAnimation from 'images/lotties/loading.json';
import Lottie from 'lottie-react';

import { useAppSelector } from 'store';

import backtestingMutation from 'hooks/useMutation/backtesting';

import { amplitudeEventNames, trackEvent } from 'features/amplitude';

import Button from 'components/Button';
import { SimpleModalProps } from 'components/Modal/ModalTypes';

import styles from 'components/Modal/PreviewInProgressModal/PreviewInProgressModal.module.scss';

type PreviewInProgressModalProps = {
  isBuy?: boolean;
} & Omit<SimpleModalProps, 'onSubmit'>;

const PreviewInProgressModal = ({
  onClose,
  isBuy,
}: PreviewInProgressModalProps) => {
  const [previewState] = useAppSelector((state) => [state.preview]);

  useEffect(() => {
    if (isBuy === undefined) {
      return;
    }
    const targetState = isBuy ? previewState.buy : previewState.sell;
    if (targetState.state || !Boolean(targetState.taskId)) {
      onClose();
    }
  }, [previewState, isBuy, onClose]);

  const { mutateAsync: revokePreview } =
    backtestingMutation.useRevokeTaskMutation();

  const handleStopPreviewButtonClick = () => {
    trackEvent(
      isBuy
        ? amplitudeEventNames.BTN_BUY_PREVIEW_CANCEL
        : amplitudeEventNames.BTN_SELL_PREVIEW_CANCEL,
    );
    if (previewState.buy.taskId) {
      revokePreview(previewState.buy.taskId);
      return;
    }
    if (previewState.sell.taskId) {
      revokePreview(previewState.sell.taskId);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {isBuy ? '매수' : '매매'} 시점을 계산하고 있으니 잠시만 기다려주세요...
      </div>
      <Lottie
        className={styles.loading}
        animationData={loadingAnimation}
        loop={true}
      />
      <Button
        onClick={handleStopPreviewButtonClick}
        size="large"
        theme="secondary1"
      >
        중단하기
      </Button>
    </div>
  );
};

export default PreviewInProgressModal;
