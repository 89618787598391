import { useRouter } from 'next/router';

import { useMutation } from '@tanstack/react-query';

import { routes } from 'constants/';

import backtestingApi from 'features/api/chart/backtesting';
import {
  BacktestingForm,
  BacktestingPreviewForm,
} from 'features/api/chart/backtesting/type';

const useCreateBacktestingTaskMutation = () => {
  const router = useRouter();

  return useMutation({
    mutationFn: (form: BacktestingForm): Promise<string> =>
      backtestingApi.createBacktestingTask(form),
    onSuccess: (taskId) => {
      router.push(routes.backtestLoadingRoute(taskId));
    },
  });
};

const useRevokeTaskMutation = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: (taskId: string): Promise<void> =>
      backtestingApi.revokeTask(taskId),
    onSuccess,
  });
};

const useCreatePreviewTask = ({ form }: { form: BacktestingPreviewForm }) => {
  return useMutation(() => backtestingApi.createBacktestingPreviewTask(form));
};

export default {
  useCreateBacktestingTaskMutation,
  useRevokeTaskMutation,
  useCreatePreviewTask,
};
