import styles from './NumberInputWithLabel.module.scss';

interface NumberInputFieldProps {
  label: string;
  value?: number;
  onChange: (value: number) => void;
}

const NumberInputField = ({
  label,
  value,
  onChange,
}: NumberInputFieldProps) => {
  return (
    <div className={styles.wrapper}>
      <label htmlFor={label} className={styles.label}>
        {label}
      </label>
      <input
        type="number"
        className={styles.input}
        id={label}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
      />
    </div>
  );
};

export default NumberInputField;
