import clsx from 'clsx';
import { useSession } from 'next-auth/react';

import { MyStrategyOverview } from 'features/api/chart/strategy/type';
import {
  convertUTCDateStringToKST,
  decimalFormatter,
  splitDateTimeOfISOString,
} from 'features/utils';

import TextHighlight from 'components/Textbox/TextHighlight';

import styles from 'components/Modal/ImportStrategyModal/ImportStrategyModalRow.module.scss';

export const ImportStrategyModalHeaderRow = () => {
  return (
    <div className={styles.headerRoot}>
      <div className={styles.createdAtColumn}>
        <span>작성 일자</span>
      </div>
      <div className={styles.titleColumn}>
        <span>전략 이름</span>
      </div>
      <div className={styles.rorColumn}>
        <span>누적 수익률</span>
      </div>
      <div className={styles.mddColumn}>
        <span>MDD</span>
      </div>
      <div className={styles.backtestingRangeColumn}>
        <span>백테스팅 기간</span>
      </div>
    </div>
  );
};

interface ImportStrategyModalRowProps {
  strategy: MyStrategyOverview;
  handleSelect: () => void;
}

export const ImportStrategyModalRow = ({
  strategy,
  handleSelect,
}: ImportStrategyModalRowProps) => {
  const { data: session } = useSession();
  const { date: createdAtDate, time: createdAtTime } = splitDateTimeOfISOString(
    convertUTCDateStringToKST(strategy.created_at),
  );

  const { date: backtestingStartDate } = splitDateTimeOfISOString(
    strategy.start,
  );

  const { date: backtestingEndDate } = splitDateTimeOfISOString(strategy.end);

  return (
    <div className={styles.root} onClick={handleSelect}>
      <div className={styles.createdAtRow}>
        <div className={styles.createdAtWrapper}>
          <span>{createdAtDate}</span>
          <span>{createdAtTime}</span>
        </div>
      </div>
      <div className={styles.titleRow}>
        <div className={styles.titleWrapper}>
          <span>{strategy.name}</span>
          {strategy.author !== session?.username && (
            <TextHighlight text={'팔로잉'} size="small" />
          )}
        </div>
      </div>
      <div className={styles.rorRow}>
        <span className={clsx(strategy.ror > 0 ? 'positive' : 'negative')}>
          {decimalFormatter(strategy.ror)}%
        </span>
      </div>
      <div className={styles.mddRow}>
        <span>{decimalFormatter(strategy.mdd)}%</span>
      </div>
      <div className={styles.backtestingRangeRow}>
        <span>
          {backtestingStartDate} ~ {backtestingEndDate}
        </span>
      </div>
    </div>
  );
};
